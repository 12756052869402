import { Box } from "@chakra-ui/react";
import LiveEvents from "@components/molecules/LiveEvents";
import { usePageTitle } from "@hooks/action/useUtils";
import React from "react";

export default function Events() {
  usePageTitle("Case Events");
  return (
    <Box bg={"white"} m={-4} p={4} minH={"90vh"}>
      <LiveEvents />
    </Box>
  );
}
