import { getEventsForCase } from "../../api/service/events";
import { useQuery } from "react-query";

const useEventApi = ({
  orderId,
  salt = "salt",
}: {
  orderId: string;
  salt: any;
}) => {
  return useQuery(
    [`events-${salt}-${orderId}`],
    () => getEventsForCase(orderId),
    {
      enabled: !!orderId,
    }
  );
};

export { useEventApi };
