import { useEffect, useState } from "react";
import PubNub from "pubnub";

let pubnubClient = new PubNub({
  publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
  subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
  uuid: import.meta.env.VITE_PUBNUB_UUID,
});

export const usePubNubHook = (uuid?: string) => {
  useEffect(() => {
    if (uuid) {
      pubnubClient = new PubNub({
        publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
        subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
        uuid,
      });
    }
  }, [uuid]);
  const [redChanel, setRedChanel] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      pubnubClient.unsubscribeAll();
    };
  }, []);

  const pusher = {
    subscribe: (channels: any[], callback: any, filterExpression?: string) => {
      if (JSON.stringify(redChanel) != JSON.stringify(channels)) {
        pubnubClient.addListener({
          message: (data: any) => {
            if (channels?.includes(data.channel)) callback(data);
          },
        });
        filterExpression && pubnubClient.setFilterExpression(filterExpression);
        pubnubClient.subscribe({ channels });
        setRedChanel(channels);
      }
    },
    unsubscribe: (channels: any, callback: any) => {
      pubnubClient.removeListener({
        message: (data: any) => {
          if (channels?.includes(data.channel)) callback(data);
        },
      });
      pubnubClient.unsubscribe({ channels });
    },
    unsubscribeAll: () => {
      pubnubClient.unsubscribeAll();
    },
    publish: pubnubClient.publish,
  };
  return pusher;
};
