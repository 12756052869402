import request from "../request";

const urls = {
  events: (order_id: string) => `/order/${order_id}/events`,
};

export const getEventsForCase = (orderId: string) => {
  const response = request("GET", `${urls.events(orderId)}`);
  return response;
};
